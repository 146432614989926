.signUpBackground {
  background: rgb(38, 113, 254);
  background: linear-gradient(
    90deg,
    rgba(38, 113, 254, 1) 0%,
    rgba(23, 43, 181, 1) 35%,
    rgba(0, 140, 255, 1) 100%
  );
  height: 240px;
  width: 88%;
  /* margin: 0 auto; */
  /* margin: 0 0 0 5%; */
  display: flex;
  justify-content: space-evenly;

  align-items: center;
  flex-direction: column;
  border-radius: var(--font32);
}

.signUpBar_description {
  font-weight: 500;
  line-height: 140%;
  color: var(--silver);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4px 0;
}
.signUpBar_description > * + * {
  margin-top: 25px;
}

.signUpWelcomeText {
  text-align: center;
}
.signUpWelcomeText2 {
  font-weight: 400;
}
.signUpBarBTN {
  background: var(--brandHover);
  border-radius: var(--font16);
  line-height: 130%;
  color: #172b85;
}
.signUpBarBTN:hover {
  background: var(--blueSilver);
  color: var(--brandPressed);
}

/* media Queeries */
@media (max-width: 600px) {
  .signUpBackground {
    height: 180px;
    width: 95%;
  }
}
@media (max-width: 550px) {
  .signUpBackground {
    height: 180px;
    width: 95%;
    padding: 10px 15px;
  }
  .signUpWelcomeText {
    line-height: 25.2px;
  }
  .signUpBarBTN {
    width: 219px;
    height: 40px;
  }
}
