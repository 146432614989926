.navBarRow {
  display: flex;
  width: 100%;
  align-items: center;
  font-weight: 400;
  justify-content: space-between;
}
.navBar {
  display: grid;
  grid-template-columns: 0.1fr 1fr;
  height: 72px;
  align-items: center;
  color: var(--silver);
  z-index: 5;

  width: 88%;
  margin: 0 auto;
}
.navBar:first-child a {
  z-index: 9999999 !important;
}

.navBarRow_primary {
  flex: 3;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  /* // dont delete this font-size */
  font-size: 14px;
  line-height: 16px;
}

.navRight {
  font-size: 16px;
  line-height: 22.4px;
}
.navBarRow_primary li,
.navRight li {
  text-decoration: none;
  list-style: none;
  color: var(--silver);
  display: flex;
}
.navBarRow_primary li a,
.navRight li a {
  text-decoration: none;
  list-style: none;
  color: var(--silver);
  position: relative;
}

.navRight {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  flex: 2;
  justify-content: flex-end;
  align-items: center;
}
.navRight > * + * {
  margin-left: 40px !important;
}

@media (max-width: 1200px) {
  .navBar {
    width: 95%;
    margin: 0 auto;
    padding: 0 20px 0 50px;
  }
  .navBarRow_primary {
    flex: 4;
  }
  .navRight > * + * {
    margin-left: 30px !important;
  }
}
@media (max-width: 991px) {
  .navBar {
    width: 95%;
    margin: 0 auto;
    /* padding: 0 20px 0 20px; */
    grid-template-columns: 0fr 1fr;

    padding: 0;
  }
  .navBarRow {
    display: flex;
    width: 100%;
    align-items: center;
    font-weight: 400;
    /* justify-content: flex-end; */
  }
  .navBarRow_primary {
    flex-direction: column;
    gap: 20px;
    flex: 10;
  }
  .navRight {
    width: 100%;
    justify-content: center;
    flex: 0;
  }
}
